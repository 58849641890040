import ProgressBar from '@ramonak/react-progress-bar';
import React from 'react'
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'

export const Skills = (props) => {
  return (
    <>
     <div className='cs_skills shadow-sm p-4 bg-w rounded mb-4'>
        <div className="cs_details">
          <h3 className='pb-3 orange-color'>{props.title}</h3>
          <p>{props.description}</p>
        </div>
        <div className='progress_bar'>
          <div className='progress_bar_items mb-3'>
            <lable><span>PHP</span>
              <span className='cs_expert'>
                <Tooltip
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Hello world!"
                />  
                Expert
              </span>
            </lable>
            
            <ProgressBar completed={90} bgColor="#66cb8c" />
          </div>
          <div className='progress_bar_items mb-3'>
            <lable><span>Wordpress</span> <span className='cs_expert'><Tooltip />  Expert</span></lable>
            <ProgressBar completed={90} bgColor="#66cb8c" />
          </div>
          <div className='progress_bar_items mb-3'>
            <lable><span>Javascript & jQuery</span> <span className='cs_expert'><Tooltip />  Expert</span></lable>
            <ProgressBar completed={80} bgColor="#66cb8c" />
          </div>
          <div className='progress_bar_items mb-3'>
            <lable><span>HTML5, CSS3, SASS & LESS</span> <span className='cs_expert'><Tooltip />  Expert</span></lable>
            <ProgressBar completed={90} bgColor="#66cb8c" />
          </div>
          
        </div>
     </div>
    </>
  )
}
export default Skills;