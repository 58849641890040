const qualification = [
  {
    course: 'MSc Computer Science',
    university: 'Guru Nanak Dev University',
    year: '(2018 - 2019)',
  },
  {
    course: 'Bachelor of Computer Applications',
    university: 'Guru Nanak Dev University',
    year: '(2015 - 2016)',
  }
]
export default qualification;