import React, { useState } from 'react';
import PortfolioData from './PortfolioData';
import { Button } from 'react-bootstrap';
import { IoIosArrowRoundForward } from "react-icons/io";

const Portfolio = (props) => {
  const [data, setData] = useState(PortfolioData);
  return (
    <>
    <div className='cs_project shadow-sm p-4 bg-w rounded mb-4'>
      <div className="cs_details">
        <h3 className='pb-3 orange-color'>{props.title}</h3>
      </div>
      <div className='portfolio_list'>
        {
          data.map((element) => (
            <div className="cs_details pb-3">
              <div className='portfolio_img'>
                <img className="w-100" src={element.portfolioImg} alt="d" />
              </div>
              <div className='portfolio_Details'>
                <h5 class="title">{element.title}</h5>
                <p>{element.description}</p>
                <a class="cs_btn" href="https://www.google.com/" target="_blank" rel="noopener noreferrer">
                  <Button className='btn-style' size='lg'>Read more <IoIosArrowRoundForward /></Button>
                </a>
              </div>
            </div>
          ))
        }
      </div>
    </div>
    </>
  );
};
export default Portfolio;
