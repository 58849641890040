import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import HeaderProfile from './Components/HeaderProfile';
import Home from './Components/Home';

function App() {
  return (
    <>
      <HeaderProfile />
      <Home />
    </>
  );
}
export default App;
