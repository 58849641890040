const PortfolioData = [
  {
    portfolioImg:'/images/vivek-portfolio/firstaid.png',
    title: "Practical & Engaging First Aid Training Courses in Bristol, Somerset & Gloucestershire",
    description: "First Aid Bristol is a family run business that has been training people in first aid for over 15 years. It was started by Indie Lyndale, who has over 30 years' experience as a teacher, lecturer, mountaineering leader and outdoor instructor. During her time working in various organisations, she experienced poor quality first aid training on countless occasions. There needed to be a different way!"
    
  },
  {
    portfolioImg:'/images/vivek-portfolio/fulllifemedical.png',
    title: "Fulllifemedical",
    description: "First Aid Bristol is a family run business that has been training people in first aid for over 15 years. It was started by Indie Lyndale, who has over 30 years' experience as a teacher, lecturer, mountaineering leader and outdoor instructor. During her time working in various organisations, she experienced poor quality first aid training on countless occasions. There needed to be a different way!"
    
  },
  {
    portfolioImg:'/images/vivek-portfolio/watchflixad.png',
    title: "Watchflixad",
    description: "First Aid Bristol is a family run business that has been training people in first aid for over 15 years. It was started by Indie Lyndale, who has over 30 years' experience as a teacher, lecturer, mountaineering leader and outdoor instructor. During her time working in various organisations, she experienced poor quality first aid training on countless occasions. There needed to be a different way!"
  },
  {
    portfolioImg:'/images/vivek-portfolio/fulllifemedical.png',
    title: "Fulllifemedical",
    description: "First Aid Bristol is a family run business that has been training people in first aid for over 15 years. It was started by Indie Lyndale, who has over 30 years' experience as a teacher, lecturer, mountaineering leader and outdoor instructor. During her time working in various organisations, she experienced poor quality first aid training on countless occasions. There needed to be a different way!"
    
  },

];
export default PortfolioData;
