import React from 'react'

const About = (props) => {
  return (
    <>
    <div className='cs_about shadow-sm p-4 bg-w rounded mb-4'>
      <div className="cs_details">
          <h3 className='pb-3 orange-color'>{props.title}</h3>
          <p>{props.description}</p>
      </div>
    </div>
    </>
  )
}

export default About;