import React, { useState } from 'react';
import {Container, Modal ,Row,Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { MdOutlineSupportAgent } from "react-icons/md";
import { IoIosArrowRoundForward } from "react-icons/io";

const ModalPop = ({ show, handleClose }) => {
  const [data, setData] = useState({
    name:'',
    email:'',
    textarea:''
  })
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({...data,[name]: value});
  };
  const handleSubmit = (e) =>{
    e.preventDefault();
  }
  return (
      <Modal 
        show={show} 
        onHide={handleClose}
        size="lg"
        centered
      >
      <Modal.Header closeButton className='justify-content-center align-items-center'>
        <Modal.Title className='fs-3'> <MdOutlineSupportAgent className='orange-color fs-1'/> Get In <span className='orange-color'>Touch</span></Modal.Title>
      </Modal.Header>
      <Container>
        <Row>
          <Col md={6}>
          <img className="w-100" src={`${process.env.PUBLIC_URL}/images/vivek-portfolio/getouch.png`} alt="Get Touch" />
          </Col>
          <Col md={6} className='py-4'>
            <Modal.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label className='orange-color'>Name</Form.Label>
                  <Form.Control type="text" name="name" onChange={handleChange}/>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className='orange-color'>Email</Form.Label>
                  <Form.Control type="email" name="email" onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="textarea">
                  <Form.Label className='orange-color'>Comment</Form.Label>
                  <Form.Control as="textarea" name="textarea" rows={3} onChange={handleChange} />
                </Form.Group>
                <a class="cs_btn" href="https://www.google.com/" target="_blank" rel="noopener noreferrer">
                  <Button className='btn-style' size='lg' type="submit">Submit <IoIosArrowRoundForward /></Button>
                </a>
              </Form>
            </Modal.Body>
          </Col>
        </Row>

      </Container>
    </Modal>
  );
};

export default ModalPop;
