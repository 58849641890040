import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import About from './About';
import Address from './Address';
import Skills from './Skills';
import Portfolio from './Portfolio';
import Education from './Education';
import Languages from './Languages';

const Home = () => {
  const [fix, setFix] = useState(false);
  useEffect(() => {
    const setfixsidebar = () => {
      if (window.scrollY >= 274) {
        setFix(true);
      } else {
        setFix(false);
      }
    };
    window.addEventListener('scroll', setfixsidebar);
    return () => {
      window.removeEventListener('scroll', setfixsidebar);
    };
  }, []);

  return (
    <>
      <Container fluid="lg" className='py-5'>
        <Row>
          <Col md={8}>
            <About
              title="About Me"
              description="Write a brief intro about yourself. It's a good idea to include your personal interests and hobbies as well. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec. Commodo ligula eget dolor. Aenean massa."
            />
            <Portfolio title="My Portfolio" />
          </Col>
          <Col md={4}>
            <div className={fix ? 'sticky-top' : ''}>
              <Address
                address="Pathankot, Punjab (145001)"
                email="vivek@idevelopingsolutions.com"
                website="vivek.idevelopingsolutions.com"
              />
              <Skills
                title="Skills"
                description="Intro about your skills goes here. Keep the list lean and only show your primary skillset. You can always provide a link to your LinkedIn or GitHub profile so people can get more info there."
              />
              <Education title="Education" />
              <Languages title="Languages" />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
