import React, { useState } from 'react';
import { FaGraduationCap } from "react-icons/fa";
import qualification from './EducationData';

const Education = (props) => {
  const [datas, setDatas] = useState(qualification);

  return (
    <div className='cs_skills shadow-sm p-4 bg-w rounded mb-4'>
      <div className="cs_details">
        <h3 className='pb-3 orange-color'>{props.title}</h3>
        {
          datas.map((element, index) => (
            <div className="cs_course" key={index}>
              <h5 className='course'> <FaGraduationCap /> {element.course}</h5>
              <p className='university'>{element.university} <span className='cs_year'>{element.year}</span></p>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default Education;
