import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import vivek from '../images/vivek.jpg';
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { IoIosArrowRoundForward } from "react-icons/io";
import profiledata from './UserDetails';
import ModalPop from './Modal';

const HeaderProfile = (props) => {
  const [show, setShow] = useState(false);
  const [isactive, setIsactive] = useState(false)

  const handleClose = () => {
    setShow(false);
    setIsactive(false);
  }
  const handleShow = () => {
    setShow(true);
    setIsactive(true);
  };
  
  return (
    <div className='bg-w shadow-sm py-4'>
      <Container>
        <Row className='align-items-center justify-content-between'>
          <Col md={6}>
            <div className='cs_profile d-block d-sm-flex align-items-center justify-content-sm-start'>
              <div className='profile_img'>
                <img src={vivek} alt="Vivek" className="rounded-circle w-100" />
              </div>
              <div className='profile_details'>
                <h1 className='fw-bold'>{profiledata.name}</h1>
                <h4>{profiledata.designation}</h4>
                <h5>Experience: <span className='orange-color'>{profiledata.experience}</span></h5>
                <span className='social-media'>
                  <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                    <FaFacebook />
                  </a>
                  <a href="https://www.linkedin.com/company/idevelopingsolutions/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin />
                  </a>
                </span>
              </div>
            </div>
          </Col>
          <Col md={6} className="text-sm-end text-center">
            <a onClick={handleShow} show={show} className={`cs_btn ${isactive ? 'active' : ''}`} target="_blank" rel="noopener noreferrer">
              <Button onClick={handleShow} className="btn-style" size='lg'>Contact Me <IoIosArrowRoundForward /></Button>
            </a>
            <ModalPop show={show} handleClose={handleClose} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeaderProfile;
