import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { FaLocationDot, FaEnvelope, FaLink } from "react-icons/fa6";

const Address = (props) => {
  return (
    <>
      <div className='cs_address shadow-sm p-4 bg-w rounded mb-4'>
        <ListGroup>
          <ListGroup.Item className='border-0 px-0'>
            <FaLocationDot /> 
            {props.address}
          </ListGroup.Item>
          <ListGroup.Item className='border-0 px-0'>
            <a href={`mailto:${props.email}`} target="_blank">
              <FaEnvelope /> 
              {props.email}
            </a>
          </ListGroup.Item>
          <ListGroup.Item className='border-0 px-0'>
            <a href={`http://${props.website}`} target="_blank">
              <FaLink /> 
              {props.website}
            </a>
          </ListGroup.Item>
        </ListGroup>
      </div>
    </>
  );
};

export default Address;
