import React from 'react'

const Languages = (props) => {
  return (
    <>
      <div className='cs_project shadow-sm p-4 bg-w rounded mb-4'>
        <div className="cs_details">
          <h3 className='pb-3 orange-color'>{props.title}</h3>
          <div className="cs_languages">
            <h6 className='pb-2'>
              <span className="languages_label">English: </span>
              <span class="lightw-color">
                Professional Proficiency
                </span >
            </h6>
            <h6 className='pb-2'>
              <span className="languages_label">Spanish: </span>
              <span class="lightw-color">
                Professional Proficiency
              </span >
            </h6>
          </div>
        </div>
      </div>
    </>
  )
}
export default Languages;
